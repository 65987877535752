import {_delete, _get, _post, _put, clubId} from "@api/services/httpService";

const URI = '/clubs/playgrounds/timetables/blocks/prices/participant-categories';

export const postBlockPriceParticipantCategory = (blockPriceCategory) => _post(URI, blockPriceCategory);

export const getParticipantCategories = (clubId) => _get('/clubs/participants/categories' + '?club.id=' + clubId + '&itemsPerPage=100');

export const putBlockPriceParticipantCategory = (blockPriceCategory) => _put(URI + '/' + blockPriceCategory.id, blockPriceCategory);

export const deleteBlockPriceParticipantCategory = (id) => _delete(URI + '/' + id);
