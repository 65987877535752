import axios from 'axios';

export const VUE_APP_NEXTORE_URL = 'https://caisse.doinsport.club';

export const VUE_APP_NEXTORE_API_URL = `${VUE_APP_NEXTORE_URL}/api`;

const httpNextore = (token) => {
  return axios.create({
    baseURL: VUE_APP_NEXTORE_API_URL,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      "X-API-KEY": token
    }
  })
};

export default httpNextore;
