import {_post, _get} from "@api/nextore/services/httpNextoreService";

export const getCategories = (token) => {

  return _get('categories', token);
}
export const getSubCategories = (token) => {

  return _get('subcategories', token);
}

export const getNextoreProducts = (category = null, subCategory = null) => {

  let params = '?';
  if (category) {
    params = params + 'category_id=' + category + '&'
  }
  if (subCategory) {
    params = params + 'subcategory_id=' + subCategory + '&'
  }

  return _get('products' + params);
}

