import {_get, _post} from "@api/nextore/services/httpNextoreService";

const URL = 'sales';

export const addSale = (sale) => {
  const params = new URLSearchParams()
  params.append('data', JSON.stringify(sale))

  return _post(URL, params, false)
}

export const getAccountSales = (token) => _get('account_sales', token);

export const getTaxRates= (token) => _get('tax_rates', token);

export const getSalesHistory = (id) => _get(URL + '?register_id=' + id);
