<template>
  <b-card>
    <header-details
      @on:cancel-edit="$router.push({name: 'duplicate'})"
    />
    <b-row>
      <b-col v-if="getSelectedBlockPrice">
        <div class="d-flex">
          <span class="modal-title font-weight-bold mt-1 mr-2">{{ $t('admin.duplicate.park-model') }}:</span>
          <d-text-field :value="getSelectedBlockPrice.club.name" class-name="bg-white" readonly size="sm"/>

          <span class="modal-title font-weight-bold mt-1 mr-2 ml-5">{{ $t('admin.duplicate.impacted-park') }}:</span>
          <d-text-field :value="impactedParks" class-name="bg-white" readonly style="width: 60%" size="sm"/>
        </div>
        <div class="d-flex mt-2">
          <span class="modal-title font-weight-bold mt-1 mr-2">{{ $t('admin.duplicate.used-cal') }}:</span>
          <d-text-field
            :value="getSelectedBlockPrice.name"
            readonly
            size="sm"
            class-name="bg-white"
            class="cursor-disabled"
          />
        </div>
      </b-col>
      <b-col v-else>
        <div class="d-flex">
          <span class="modal-title font-weight-bold mr-3 mt-1">{{ $t('admin.duplicate.impacted-park') }}:</span>
          <d-text-field :value="impactedParks" class-name="bg-white" readonly style="width: 60%" size="sm"/>
        </div>
      </b-col>
    </b-row>

    <b-row class="d-flex justify-content-center mt-2">
      <b-col v-if="getSelectedBlockPrice" cols="7">
        <fieldset>
          <legend class="d-flex justify-content-center font-weight-bold h6">
            {{
              getSelectedBlockPrice.club.name
            }}
          </legend>
          <block-price-form
            :block-price="getSelectedBlockPrice"
          />
          <div class="d-flex justify-content-center">
            <d-button
              :text="$t('admin.duplicate.set-data')"
              class="d-btn-sm d-btn btn d-btn-success font-text-title mt-3"
              @on:button-click="onDuplicateAll"
            />
          </div>
        </fieldset>
      </b-col>
      <b-col cols="12">
        <div style="height: 40px" class="w-100 bg-light-red d-flex justify-content-center align-items-center">
          {{ $t('admin.duplicate.block-price-to-edit') }}
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col
        v-for="(item, index) of groupedSelectedBlockPricesByClub"
        :key="'checked-' + index"
        cols="6"
        class="mt-3"
      >
        <fieldset>
          <legend style="width: auto" class="d-flex font-weight-bold h6">
            <span class="ml-2 mr-2">{{ item.club.name }}</span>
          </legend>
          <template
            v-for="(blockPrice, index) of item.categories"
          >
            <block-price-form
              :key="'index' + index + keyMap"
              :block-price="blockPrice"
              :has-border="index < item.categories.length - 1"
              @reload-all-editor="keyMap ++"
            />
          </template>
        </fieldset>
      </b-col>

      <b-col cols="12" align="middle">
        <d-button
          :text="$t('admin.duplicate.validate-data')"
          class="d-btn-sm d-btn btn d-btn-success font-text-title mt-3"
          @on:button-click="displayModal"
        />
      </b-col>
      <progress-bar-modal
        modalId="identifier-validate"
        :display="display"
        title="general.actions.notification"
        :is-busy="isBusy"
        :count="count"
        :text="$t('admin.duplicate.ask-for-confirmation')"
        :hide="hide"
        @on:cancel="hide = !hide"
        @on:confirm="onValidate"
      />
    </b-row>
  </b-card>
</template>

<script>
import HeaderDetails from "@views/duplicate/HeaderDetails";
import DuplicateShopCategoryForm from "@custom/duplicate/DuplicateShopCategoryForm";
import {isNotUndefinedAndNotNull} from "@/utils/classes";
import {_put} from "@api/services/httpService";
import ProgressBarModal from "@custom/duplicate/ProgressBarModal";
import PermanentForm from "@views/duplicate/calendar/PermanentForm";
import {cloneData} from "@/utils/form";
import HolidaysForm from "@views/duplicate/calendar/HolidaysForm";
import BlockPriceForm from "@views/duplicate/block-price/BlockPriceForm";
import Price from "@/classes/Price";

export default {
  data: () => ({
    display: false,
    hide: false,
    keyMap: 0,
    count: {
      success: 0,
      failed: 0,
      totalCount: 0,
    },
    isBusy: false
  }),
  computed: {
    getSelectedBlockPrice() {
      return this.$store.getters['blockPrice/getSelectedBlockPriceModel'];
    },
    type() {
      return this.$route.params.type;
    },
    getCheckedBlockPriceList() {
      return this.$store.getters['blockPrice/getCheckedBlockPrices'];
    },
    impactedParks() {
      let label = '';

      for (let i = 0; i < this.groupedSelectedBlockPricesByClub.length; i++) {
        label += i < this.groupedSelectedBlockPricesByClub.length - 1 ? this.groupedSelectedBlockPricesByClub[i].club.name + ',' : this.groupedSelectedBlockPricesByClub[i].club.name;
      }
      return label;
    },
    groupedSelectedBlockPricesByClub() {
      let groupList = [];

      for (const item of this.getCheckedBlockPriceList) {
        if (groupList.length > 0) {
          const checkIfAdded = groupList.find(el => el.club.id === item.club.id);

          if (isNotUndefinedAndNotNull(checkIfAdded)) {
            checkIfAdded.categories.push(item);
          } else {
            groupList.push({club: item.club, categories: [item]});
          }
        } else {
          groupList.push({club: item.club, categories: [item]});
        }
      }

      return groupList.sort((b, a) => a.categories.length - b.categories.length);
    }
  },
  components: {BlockPriceForm, HolidaysForm, PermanentForm, ProgressBarModal, HeaderDetails},
  methods: {
    displayModal() {
      this.count.success = 0;
      this.count.failed = 0;
      this.count.totalCount = this.getCheckedBlockPriceList.length;
      this.display = !this.display;
    },
    async onValidate() {
      if (false === this.isBusy) {
        this.isBusy = true;
        this.count = {success: 0, failed: 0, totalCount: this.getCheckedBlockPriceList.length}

        for (let g = 0; g < this.groupedSelectedBlockPricesByClub.length; g++) {
          const group = this.groupedSelectedBlockPricesByClub[g];

          for (let i = 0; i < group.categories.length; i++) {
            const blockPrice = group.categories[i];
            const participantCategories = [];
            for (const participantCategory of blockPrice.participantCategories) {
              participantCategories.push({
                id: participantCategory.id,
                category: participantCategory.category,
                pricePerParticipant: parseInt(participantCategory.pricePerParticipant * 100)
              })
            }
            await _put(blockPrice['@id'], {
              name: blockPrice.name,
              label: blockPrice.label,
              activity: blockPrice.activity,
              bookingComment: blockPrice.bookingComment,
              minParticipantsCountLimit: blockPrice.minParticipantsCountLimit,
              maxParticipantsCountLimit: blockPrice.maxParticipantsCountLimit,
              maxBookingCountLimit: blockPrice.maxBookingCountLimit,
              photos: blockPrice.photos,
              form: blockPrice.form,
              participantCategories: participantCategories,
              playgroundOptions: blockPrice.playgroundOptions,
              duration: blockPrice.duration * 60,
              position: blockPrice.position,
              nextoreProduct: blockPrice.nextoreProduct,
              category: blockPrice.category,
              description: blockPrice.description,
              registrationAvailableOnlineFrom: blockPrice.registrationAvailableOnlineFrom,
              registrationAvailableOnlineTo: blockPrice.registrationAvailableOnlineTo,
              pricePerParticipant: blockPrice.pricePerParticipant * 100,
              availableOnBackOffice: blockPrice.availableOnBackOffice,
              registrationAvailableOnline: blockPrice.registrationAvailableOnline,
            })
              .then((response) => {
                this.count.success++;
              })
              .catch((err) => {
                this.count.failed++;
              })
              .finally(() => {
                if (this.count.success + this.count.failed === this.count.totalCount) {
                  this.isBusy = false;
                  if (g === this.groupedSelectedBlockPricesByClub.length - 1 && this.count.failed === 0) {
                    this.$nextTick(() => {
                      setTimeout(() => {
                        this.$router.replace({name: 'duplicate'});
                        this.$vs.notify({
                          time: 5000,
                          title: this.$t('general.actions.notification'),
                          text: this.$t('general.actions.success-update'),
                          color: 'success',
                          position: 'top-right'
                        });
                      }, 2000)
                    })
                  }

                  if (g === this.groupedSelectedBlockPricesByClub.length - 1 && this.count.failed > 0) {
                    this.hide = !this.hide;
                  }
                }
              })
            ;
          }
        }
      }
    },
    onDuplicateAll() {
      const blockPrice = cloneData(this.getSelectedBlockPrice);
      delete blockPrice.club;
      delete blockPrice['@id'];

      this.$store.commit('blockPrice/updateBlockPriceCheckedList', blockPrice);
    }
  },

  mounted() {
    console.log(this.groupedSelectedBlockPricesByClub, 'loog me opn mounted');
  }
}
</script>
<style scoped lang="scss">

.bg-light-red {
  background-color: #FF0101;
  border-radius: 5px;
  color: white;
  font-weight: bold;
}

/deep/ .modal-content {
  border-radius: 8px;
  width: calc(100% - 294px);
  left: 100px;
}

@import "@lazy/_b-card.scss";
</style>
