<template>
  <div>
    <b-row>
      <b-col cols="12" align="middle">
        <div class="product-box mt-2">
          <div class="product-img">
            <img v-if="blockPrice.mainPhoto" height="140" width="180" :src="$filePath(blockPrice.mainPhoto.contentUrl)">

            <img
              v-else
              class="pointer"
              height="140" width="180"
              src="@/assets/upload-img.svg"
              @click="upload"
              alt
            />
            <div class="product-hover">
              <ul
                v-if="blockPrice.mainPhoto === null"
              >
                <li
                  class="pointer"
                  @click="upload"
                >
                  <button
                    class="btn"
                    type="button"
                  >
                    <i class="icon-upload"></i>
                  </button>
                </li>
              </ul>
              <ul v-else>
                <li
                  class="pointer"
                  @click="resetPhoto"
                >
                  <button
                    class="btn"
                    type="button"
                  >
                    <i class="icon-trash"></i>
                  </button>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <label class="d-flex justify-content-center"> Image
          <i @click="onCopy('photos');onCopy('mainPhoto')" class="pointer mt-1 ml-2 fa-15x" :class="icon"></i>
        </label>
      </b-col>
      <b-col cols="6">

        <label> {{ $t('admin.duplicate.block-price.name') }}</label>
        <i @click="onCopy('name')" class="pointer ml-2 fa-15x" :class="icon"></i>

        <span class="d-flex w-100">
           <d-text-field
             v-model="blockPrice.name"
             error-text="validation.text-input-three-characters"
             class="btn-full-width"
             class-name="bg-white"
             size="sm"
           />
        </span>
      </b-col>
      <b-col cols="6">
        <label> {{ $t('admin.duplicate.block-price.label') }}</label>
        <i @click="onCopy('label')" class="pointer ml-2 fa-15x" :class="icon"></i>

        <span class="d-flex w-100">
           <d-text-field
             v-model="blockPrice.label"
             error-text="validation.text-input-three-characters"
             class="btn-full-width"
             class-name="bg-white"
             size="sm"
           />
        </span>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col cols="6">

        <label> {{ $t('admin.duplicate.block-price.type.label') }}</label>

        <span class="d-flex w-100">
          <select
            size="sm"
            class="form-control"
          >
            <option selected>
               {{ $t('admin.duplicate.block-price.type.' + blockPrice.activityType) }}
            </option>
          </select>
        </span>
      </b-col>
      <b-col cols="6">
        <label> {{ $t('admin.duplicate.block-price.activity') }}</label>
        <span class="d-flex w-100">
           <select
             v-model="blockPrice.activity"
             size="sm"
             class="form-control"
           >
             <option disabled :value="null">
               {{ $t('general.actions.undefined') }}
             </option>
             <template v-for="(activity, index) of activities">
               <option :key="'activity-id' + index" :value="activity['@id']">
                   {{ activity.name }}
               </option>
             </template>
          </select>
        </span>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col v-if="blockPrice.activityType === 'formula'" cols="6">
        <label> {{ $t('admin.duplicate.block-price.minParticipantsCountLimit') }}</label>
        <i @click="onCopy('minParticipantsCountLimit')" class="pointer ml-2 fa-15x" :class="icon"></i>

        <span class="d-flex w-100">
          <d-text-field
            v-model.number="blockPrice.minParticipantsCountLimit"
            class="btn-full-width"
            class-name="bg-white"
            type="number"
            size="sm"
          />
        </span>
      </b-col>
      <b-col :cols="6">
        <label> {{ $t('admin.duplicate.block-price.maxParticipantsCountLimit') }}</label>
        <i @click="onCopy('maxParticipantsCountLimit')" class="pointer ml-2 fa-15x" :class="icon"></i>

        <span class="d-flex w-100">
          <d-text-field
            v-model.number="blockPrice.maxParticipantsCountLimit"
            type="number"
            class="btn-full-width"
            class-name="bg-white"
            size="sm"
          />
        </span>
      </b-col>
      <b-col cols="6">
        <label> {{ $t('admin.duplicate.block-price.position') }} </label>
        <i @click="onCopy('position')" class="pointer ml-2 fa-15x" :class="icon"></i>
        <span class="d-flex w-100">
           <d-text-field
             v-model.number="blockPrice.position"
             error-text="validation.text-input-three-characters"
             class="btn-full-width"
             class-name="bg-white"
             type="number"
             size="sm"
           />
        </span>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col>
        <label class="title-step">
          {{ $t('admin.duplicate.block-price.rates.nextore-rates') }}
        </label>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="4">
        <label>
          {{ $t('admin.duplicate.block-price.rates.code') }}
        </label>
        <div class="d-flex flex-row align-content-center align-items-center">
          <select
            v-model="blockPrice.nextoreProduct.accountSale"
            class="form-control custom-select-icon-option"
            size="sm"
          >
            <option
              :value="blockPrice.nextoreProduct.accountSale === '' ? '' : null"
            >
              {{ $t('general.actions.undefined') }}
            </option>
            <template v-for="(saleAccount, iterator) of salesAccount">
              <option
                :key="'saleAccount' + iterator"
                :value="saleAccount.name"
              >
                {{ saleAccount.name }}
              </option>
            </template>
          </select>
        </div>
      </b-col>
      <b-col cols="4">
        <label>
          {{ $t('admin.duplicate.block-price.rates.tax') }}
        </label>
        <div class="d-flex flex-row align-content-center align-items-center">
          <select
            v-model="blockPrice.nextoreProduct.taxRate"
            class="form-control custom-select-icon-option"
            size="sm"
          >
            <option
              :value="blockPrice.nextoreProduct.taxRate === '' ? '' : null"
            >
              {{ $t('general.actions.undefined') }}
            </option>
            <template v-for="(taxRate, iterator) of taxRates">
              <option
                :key="'saleAccount' + iterator"
                :value="taxRate.id"
              >
                {{ taxRate.name }}
              </option>
            </template>
          </select>
        </div>

      </b-col>
      <b-col cols="4">
        <label>
          {{ $t('admin.duplicate.block-price.rates.category.nextore-category') }}
        </label>
        <div class="d-flex flex-row align-content-center align-items-center">
          <select
            size="sm"
            v-model="blockPrice.nextoreProduct.category"
            class="form-control custom-select-icon-option"
            @change="blockPrice.nextoreProduct.subCategory = null"
          >
            <option
              :value="blockPrice.nextoreProduct.category === '' ? '' : null"
            >
              {{ $t('general.actions.undefined') }}
            </option>
            <template v-for="(category, iterator) of nextoreCategories">
              <option
                :key="'saleAccount' + iterator"
                :value="category.id"
              >
                {{ category.name }}
              </option>
            </template>
          </select>
        </div>

      </b-col>
      <b-col class="mt-2" cols="4">
        <label>
          {{ $t('admin.duplicate.block-price.rates.category.nextore-sub-category') }}
        </label>
        <div class="d-flex flex-row align-content-center align-items-center">
          <select
            size="sm"
            v-model="blockPrice.nextoreProduct.subCategory"
            class="form-control custom-select-icon-option"
          >
            <option
              :value="blockPrice.nextoreProduct.subCategory === '' ? '' : null"
            >
              {{ $t('general.actions.undefined') }}
            </option>
            <template v-for="(subCategory, iterator) of nextoreSubCategories">
              <option
                v-if="subCategory.category_id === blockPrice.nextoreProduct.category"
                :key="'saleAccount' + iterator"
                :value="subCategory.id"
              >
                {{ subCategory.name }}
              </option>
            </template>
          </select>
        </div>
      </b-col>
      <b-col class="mt-2" cols="4">
        <label>
          {{ $t('admin.duplicate.block-price.rates.nature') }}
        </label>
        <div class="d-flex flex-row align-content-center align-items-center">
          <select
            size="sm"
            v-model="blockPrice.nextoreProduct.nature"
            class="form-control custom-select-icon-option"
          >
            <option
              :value="blockPrice.nextoreProduct.nature === '' ? '' : null"
            >
              {{ $t('general.actions.undefined') }}
            </option>
            <template v-for="(item, iterator) of natures">
              <option
                :key="'nature' + iterator"
                :value="item.nature"
              >
                {{ item.nature }}
              </option>
            </template>
          </select>
        </div>
      </b-col>
      <b-col class="mt-2 mb-3" cols="4">
        <label>
          {{ $t('admin.duplicate.block-price.rates.center') }}
        </label>
        <div class="d-flex flex-row align-content-center align-items-center">
          <select
            size="sm"
            v-model="blockPrice.nextoreProduct.center"
            class="form-control custom-select-icon-option"
          >
            <option
              :value="blockPrice.nextoreProduct.center === '' ? '' : null"
            >
              {{ $t('general.actions.undefined') }}
            </option>
            <template v-for="(item, iterator) of centers">
              <option
                :key="'center' + iterator"
                :value="item.center"
              >
                {{ item.center }}
              </option>
            </template>
          </select>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col cols="6">
        <label> {{ $t('admin.duplicate.block-price.simultaneous-bookings') }}</label>
        <i @click="onCopy('maxBookingCountLimit')" class="pointer ml-2 fa-15x" :class="icon"></i>

        <span class="d-flex w-100">
          <d-text-field
            v-model.number="blockPrice.maxBookingCountLimit"
            class="btn-full-width"
            class-name="bg-white"
            type="number"
            size="sm"
          />
        </span>
      </b-col>
      <b-col cols="6">
        <label> {{ $t('admin.duplicate.block-price.duration') }}</label>
        <i @click="onCopy('duration')" class="pointer ml-2 fa-15x" :class="icon"></i>

        <span class="d-flex w-100">
           <d-text-field
             v-model.number="blockPrice.duration"
             type="number"
             class="btn-full-width"
             class-name="bg-white"
             size="sm"
           />
        </span>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col
        :cols="6"
        class="d-flex align-content-center align-items-center"
      >
        <vs-checkbox
          v-model="blockPrice.availableOnBackOffice"
          color="primary"
          value="false"
        />
        <p class="mt-3">
          {{ $t('admin.duplicate.block-price.club-bookable') }}
        </p>
        <i @click="onCopy('availableOnBackOffice')" class="pointer ml-2 fa-15x" :class="icon"></i>

      </b-col>
      <b-col
        :cols="6"
        class="d-flex align-content-center align-items-center"
      >
        <vs-checkbox
          v-model="blockPrice.registrationAvailableOnline"
          color="primary"
          value="false"

        />
        <p class="mt-3">
          {{ $t('admin.duplicate.block-price.app-bookable') }}
        </p>
        <i @click="onCopy('registrationAvailableOnline')" class="pointer ml-2 fa-15x" :class="icon"></i>
      </b-col>

    </b-row>

    <b-row>
      <b-col cols="6">

        <label> {{ $t('admin.duplicate.block-price.category') }}</label>
        <span class="d-flex w-100">
            <select
              v-model="blockPrice.category"
              size="sm"
              class="form-control"
            >
              <option :value="null"> {{ $t('general.actions.undefined') }}</option>
              <template v-for="(category,index) of categories">
                <option :key="'category' + index" :value="category['@id']" selected>
                   {{ category?.name }}
                </option>
              </template>
          </select>
        </span>
      </b-col>
      <b-col v-if="blockPrice.activityType === 'leisure'" cols="6">

        <label> {{ $t('admin.duplicate.block-price.form') }}</label>
        <span class="d-flex w-100">
            <select
              v-model="blockPrice.form"
              size="sm"
              class="form-control"
            >
              <option :value="null"> {{ $t('general.actions.undefined') }}</option>
              <template v-for="(form,index) of forms">
                <option :key="'category' + index" :value="form['@id']" selected>
                   {{ form?.name }}
                </option>
              </template>
          </select>
        </span>
      </b-col>
      <b-col v-if="blockPrice.activityType === 'formula'" cols="6">

        <label> {{ $t('admin.duplicate.block-price.public-price') }}</label>
        <i @click="onCopy('pricePerParticipant')" class="pointer ml-2 fa-15x" :class="icon"></i>
        <span class="d-flex w-100">
           <d-text-field
             v-model.number="blockPrice.pricePerParticipant"
             error-text="validation.text-input-three-characters"
             class="btn-full-width"
             class-name="bg-white"
             type="number"
             size="sm"
           />
        </span>
      </b-col>
    </b-row>
    <b-row>
      <b-col class="mt-2" cols="12">
         <label>{{ $t('admin.duplicate.block-price.available') }}</label>
      </b-col>

      <b-col cols="6">
        <label>{{ $t('admin.duplicate.block-price.from') }}</label>
        <i @click="onCopy('registrationAvailableOnlineFrom')" class="pointer ml-2 fa-15x" :class="icon"></i>
        <d-inline-calendar
          v-model="blockPrice.registrationAvailableOnlineFrom"
          :label="blockPrice.registrationAvailableOnlineFrom ? $moment(blockPrice.registrationAvailableOnlineFrom).format('DD/MM/YYYY HH:mm') : $t('general.actions.undefined')"
          class-name="width-auto w-100"
          identifier="fromDate"
          type="datetime"
          @on:select="blockPrice.registrationAvailableOnlineFrom = $event"
        />
      </b-col>
      <b-col cols="6">
        <label>{{ $t('admin.duplicate.block-price.to') }}</label>
        <i @click="onCopy('registrationAvailableOnlineTo')" class="pointer ml-2 fa-15x" :class="icon"></i>
        <d-inline-calendar
          v-model="blockPrice.registrationAvailableOnlineTo"
          :label="blockPrice.registrationAvailableOnlineTo ? $moment(blockPrice.registrationAvailableOnlineTo).format('DD/MM/YYYY HH:mm') : $t('general.actions.undefined')"
          class-name="width-auto w-100"
          identifier="fromDate"
          type="datetime"
          @on:select="blockPrice.registrationAvailableOnlineTo = $event"
        />
      </b-col>
    </b-row>
    <b-row v-if="blockPrice.activityType === 'leisure'" class="mt-2">
      <b-col cols="12">
        <label> {{ $t('admin.duplicate.block-price.public-rates') }}</label>
        <b-row
          v-for="(participantCategory, index) of blockPrice.participantCategories"
        >
          <b-col
            :key="'participant-category-' + index"
            cols="12"
          >
            <b-row class="mt-2">
              <b-col>
              <span>{{ $t('admin.duplicate.block-price.category') }}</span>
              <select
                v-model="participantCategory.category"
                size="sm"
                class="form-control"
              >
                <option :value="null"> {{ $t('general.actions.undefined') }}</option>
                <template v-for="(category, index) of participantCategories">
                  <option :key="'category' + index" :value="category['@id']" selected>
                    {{ category?.name }}
                  </option>
                </template>
              </select>
            </b-col>
              <b-col>
                <span>{{ $t('admin.duplicate.block-price.public-price') }}</span>
                <d-text-field
                  v-model="participantCategory.pricePerParticipant"
                  size="sm"
                  type="number"
                />
              </b-col>
            </b-row>
          </b-col>
        </b-row>

      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col cols="12">

        <label> {{ $t('admin.duplicate.block-price.options') }}</label>
        <b-row v-if="options.length > 0">
          <b-col
            v-for="(option, index) of options"
            :key="'option-' + index"
            cols="6"
            class="mt-2 d-flex justify-content-start"
          >
            <vs-checkbox
              v-model="blockPrice.playgroundOptions"
              vs-name="options"
              :vs-value="option['@id']"
              color="primary"
            >
              {{ option.name }}
            </vs-checkbox>
          </b-col>
        </b-row>
        <b-row v-else>
          <b-col class="font-weight-bold text-warning" cols="12">
            Il n'y a pas d'option disponible pour ce club.
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col cols="12">
        <label class="row col-12"> {{ $t('admin.duplicate.shop-category.description') }}
          <i @click="onCopy('description')" class="pointer ml-2 mt-1 fa-15x" :class="icon"></i>
        </label>

        <span class="d-flex w-100">
          <email-editor
            :default-content="blockPrice.description"
            @on:editor-content:update="blockPrice.description = $event"
          />
        </span>
      </b-col>
    </b-row>

    <b-row class="mt-2">
      <b-col cols="12">
        <label class="row col-12">
          {{ $t('admin.duplicate.block-price.mail') }}
          <i @click="onCopy('bookingComment')" class="pointer ml-2 mt-1 fa-15x" :class="icon"></i>

        </label>
        <label class="font-weight-light"> {{ $t('admin.duplicate.block-price.inf-sup') }}</label>

        <span class="d-flex w-100">
          <email-editor
            :default-content="blockPrice.bookingComment"
            @on:editor-content:update="blockPrice.bookingComment = $event"
          />
        </span>
      </b-col>
    </b-row>

    <div v-if="hasBorder" class="border-bottom-black mt-3 mb-3"/>

  </div>
</template>
<script>
import EmailEditor from "@custom/EmailEditor";
import {getBlockPriceCategories, getClubForms} from "@api/services/timetable/blocks/price/category/category.api";
import {getActivitiesByClubId} from "@api/services/activities/activities.api";
import {getClubOptions} from "@api/services/timetable/blocks/price/option/option.api";
import {fromIdToIriReference} from "@/utils/form";
import {URI_BLOCK_PRICE} from "@api/services/timetable/blocks/price/price.api";
import {_delete} from "@api/services/httpService";
import {getParticipantCategories} from "@api/services/timetable/blocks/price/participant-category/participant-category.api";
import {getCategories, getSubCategories} from "@api/nextore/services/product.api";
import {getAccountSales, getTaxRates} from "@api/nextore/services/sale.api";
import {getCenters} from "@api/services/nextore/center.api";
import {getNatures} from "@api/services/nextore/nature.api";

export default {
  components: {EmailEditor},
  data: () => ({
    options: [],
    activities: [],
    forms: [],
    categories: [],
    participantCategories: [],
    taxRates: [],
    centers: [],
    natures: [],
    nextoreCategories: [],
    nextoreSubCategories: [],
    salesAccount: [],
  }),
  props: {
    blockPrice: {
      type: Object,
      default: this
    },
    icon: {
      type: String,
      default: 'icofont icofont-download-alt text-danger'
    },
    hasBorder: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    loadCategories() {
      if (null !== this.blockPrice.activityType) {
        getBlockPriceCategories(this.blockPrice.activityType, this.blockPrice.club.id)
          .then((response) => {
            this.categories = response.data['hydra:member'];
          })
        ;
      }
    },
    loadForms() {
      getClubForms(this.blockPrice.club.id)
        .then((response) => {
          this.forms = response.data['hydra:member'];
        })
      ;
    },
    loadActivities() {
      getActivitiesByClubId(this.blockPrice.club.id)
        .then((response) => {
          this.activities = response.data['hydra:member'];
        })
      ;
    },
    loadParticipantCategories() {
      getParticipantCategories(this.blockPrice.club.id)
        .then((response) => {
          this.participantCategories = response.data['hydra:member'];
        })
      ;
    },
    upload() {
      this.$upload(this.uploaded, {
        entry: 'blockPrice',
        url: fromIdToIriReference(URI_BLOCK_PRICE, this.blockPrice.id),
        target: 'postBlockPricePhoto'
      });
    },
    resetPhoto() {
      if (this.blockPrice.id && this.blockPrice.mainPhoto) {
        _delete(this.blockPrice.mainPhoto['@id']);
      }

      this.blockPrice.mainPhoto = null;
    },
    uploaded(resp) {
      this.blockPrice.mainPhoto = resp;
      this.blockPrice.photos = [resp];
    },
    onCopy(key) {
      const payload = {};
      payload[key] = this.blockPrice[key];

      this.$store.commit('blockPrice/updateBlockPriceCheckedList', payload);
      this.$emit('reload-all-editor');
    },
    loadOptions() {
      this.options = [];
      const clubId = this.blockPrice.club.id;

      getClubOptions(clubId)
        .then((response) => {
          this.options = response.data['hydra:member']
        })
      ;
    },
    loadSalesAccount() {
      getAccountSales(this.blockPrice.club.nextoreAccount.apiToken)
        .then((response) => {
          this.salesAccount = response.data;
        })
      ;
    },
    loadNatures() {
      getNatures()
        .then((response) => {
          this.natures = response.data['hydra:member'];
        })
      ;
    },
    loadCenters() {
      getCenters()
        .then((response) => {
          this.centers = response.data['hydra:member'];
        })
      ;
    },
    loadTaxRates() {
      getTaxRates(this.blockPrice.club.nextoreAccount.apiToken)
        .then((response) => {
          this.taxRates = response.data;
        })
      ;
    },
    loadNextoreCategories() {
      getCategories(this.blockPrice.club.nextoreAccount.apiToken)
        .then((response) => {
          this.nextoreCategories = response.data;
        })
      ;
    },
    loadNextoreSubCategories() {
      getSubCategories(this.blockPrice.club.nextoreAccount.apiToken)
        .then((response) => {
          this.nextoreSubCategories = response.data;
        })
      ;
    }
  },
  mounted() {
    console.log('on mount', this.blockPrice.nextoreProduct)
    this.loadCategories();
    this.loadParticipantCategories();
    this.loadActivities();
    this.loadOptions();
    this.loadForms();
    this.loadSalesAccount();
    this.loadNatures();
    this.loadCenters();
    this.loadNextoreCategories();
    this.loadNextoreSubCategories();
    this.loadTaxRates();  }
}
</script>
<style lang="scss" scoped>

/deep/ .checkbox_x {
  max-width: 20px;
  min-width: 20px;
  max-height: 20px;
  min-height: 20px;
}
</style>
